import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./authorHeader.scss";

const AuthorHeader = (props) => {
  const {
    headline,
    description,
    image,
    socialMedia,
  } = props;

  return (
    <Row className="author-header align-items-center">
      {image && (
        <Col xs={4} md={5} lg={{ offset: 1, span: 4 }}>
          <div className="author-header__image-container">
            {image}
          </div>
        </Col>
      )}
      <Col md={8} lg={7} className="order-lg-first">
        <h2>{headline}</h2>
        <div className="typo-h4">{description}</div>
        {socialMedia}
      </Col>
    </Row>
  );
};

AuthorHeader.propTypes = {
  description: PropTypes.string,
  headline: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
  socialMedia: PropTypes.oneOfType([PropTypes.object]),
};

AuthorHeader.defaultProps = {
  description: null,
  image: null,
  socialMedia: null,
};

export default AuthorHeader;
