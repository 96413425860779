import React from "react";

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import AuthorHeader from "../../components/authorHeader/authorHeader";
import FilterDropdown from "../../components/filterDropown/filterDropdown";
import SEO from "../../components/seo";
import SocialMediaShare from "../../components/socialMediaShare/socialMediaShare";
import CampaignCard from "../campaigns/campaignCard";
import DonorCard from "../donorArea/donorCard";
import KontentItemImage from "../kontentItemImage";
import MainLayout from "../layout/mainLayout";
import LearnCard from "../learn/learnCard";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import NewsCard from "../news/newsCard";
import PressReleaseTeaserCard from "../press/pressReleaseTeaserCard";

const KontentItemAuthorDetailsPage = ({ data }) => {
  const intl = useIntl();

  return (
    <MainLayout>
      <SEO
        title={data.page.elements.metadata__meta_title.value}
        description={data.page.elements.metadata__meta_description.value}
        twitterTitle={data.page.elements.metadata__twitter_title.value}
        twitterSite={data.page.elements.metadata__twitter_site.value}
        twitterImage={data.page.elements.metadata__twitter_image.value}
        twitterDescription={data.page.elements.metadata__twitter_description.value}
        twitterCreator={data.page.elements.metadata__twitter_creator.value}
        ogTitle={data.page.elements.metadata__og_title.value}
        ogImage={data.page.elements.metadata__og_image.value}
        ogDescription={data.page.elements.metadata__og_description.value}
      />

      <BreadcrumbGenerator id={data.page.id} />

      <AuthorHeader
        headline={data.page.elements.contact_item.value[0].elements.name.value}
        description={data.page.elements.long_description.value}
        image={(
          <KontentItemImage
            height={300}
            width={300}
            className="rounded-circle"
            data={data.page.elements.contact_item.value.length > 0
            && data.page.elements.contact_item.value[0].elements.image.value.length > 0
              ? data.page.elements.contact_item.value[0].elements.image
              : null}
          />
        )}
        socialMedia={(
          <SocialMediaShare
            followLinks={data.siteSettings
              ? data.siteSettings.elements
              : null}
          />
        )}
      />

      <FilterDropdown
        headline={intl.formatMessage({ id: "author_details.stories" })}
        useOnAuthorPage
      >
        {NewsCard({
          author: data.page.elements.author_name.value[0].codename,
          currentFirst: true,
          data: data.stories.nodes,
        })}
      </FilterDropdown>

      <FilterDropdown
        headline={intl.formatMessage({ id: "author_details.campaign" })}
        hideFilter
        useOnAuthorPage
      >
        {CampaignCard({
          author: data.page.elements.author_name.value[0].codename,
          data: data.campaigns.nodes,
          tile: true,
        })}
      </FilterDropdown>

      <FilterDropdown
        headline={intl.formatMessage({ id: "author_details.press" })}
        useOnAuthorPage
      >
        {PressReleaseTeaserCard({
          author: data.page.elements.author_name.value[0].codename,
          data: data.articlePress.nodes,
        })}
      </FilterDropdown>

      <FilterDropdown
        headline={intl.formatMessage({ id: "author_details.learn_article" })}
        hideFilter
        variant="learn-area"
        useOnAuthorPage
      >
        {LearnCard({
          author: data.page.elements.author_name.value[0].codename,
          data: data.learnArticles.nodes,
          variant: "learn-topic",
        })}
      </FilterDropdown>

      <FilterDropdown
        headline={intl.formatMessage({ id: "author_details.donor_area" })}
        variant="learn-area"
        hideFilter
        useOnAuthorPage
      >
        {DonorCard({
          author: data.page.elements.author_name.value[0].codename,
          data: data.articleDonor.nodes,
          variant: "learn-topic",
        })}
      </FilterDropdown>

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    articleDonor: allKontentItemArticleDonorArea {
      nodes {
        ...DonorCard
      }
    }
    articlePress: allKontentItemArticlePress {
      nodes {
        ...PressReleaseTeaserCard
      }
    }
    campaigns: allKontentItemArticlePageCampaign {
      nodes {
        ...CampaignCard
      }
    }
    stories: allKontentItemArticlePageNews {
      nodes {
        ...NewsCard
      }
    }
    learnArticles: allKontentItemArticlePageLearn {
      nodes {
        ...LearnCard
      }
    }
    siteSettings: kontentItemSiteSettings(system: {codename: {eq: "site_settings"}}) {
      elements {
        url_facebook {
          value
        }
        url_twitter {
          value
        }
      }
    }
  
    page: kontentItemAuthorDetails(id: {eq: $id}) {
      id
      elements {
        author_name {
          value {
            codename
          }
        }
        long_description {
          value
        }
        contact_item {
          value {
            ...KontentItemContact
          }
        }
        metadata__meta_description {
          value
        }
        metadata__meta_title {
          value
        }
        metadata__og_description {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_title {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_title {
          value
        }
      }
    }
  }
`;

KontentItemAuthorDetailsPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemAuthorDetailsPage;
